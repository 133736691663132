<div class="container px-5 mb-5">
    <div class="row">

        <div 
            class="col-12 text-fifth text-center mb-3"
            style="font-size: calc(2em + 2vh)">
            <b>Requisitos para tramitar tu préstamo</b>
        </div>
        <div 
            class="col-12"
            style="font-size: calc(0.8em + 0.8vh);">
            <div class="row">

                <div class="col-12 col-md-6 col-lg-3">
                    <img 
                        src="/assets/media/icons/INE.png" 
                        alt="Ícono de INE verde"
                        class="mx-auto d-block"
                        width="70%">
                    <div class="text-uppercase text-center px-0">
                        <b>Identificación oficial vigente</b>
                    </div>
                    <div class="text-center mb-3">
                        INE/Pasaporte
                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-3">
                    <img 
                        src="./assets/media/icons/COMPROBANTE-DE-DOMICILIO.png" 
                        alt="Ícono de comprobante de domicilio verde"
                        class="mx-auto d-block"
                        width="70%">
                    <div class="text-uppercase fw-bold text-center mb-3">
                        Comprobante de domicilio
                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-3">
                    <img src="./assets/media/icons/REFERENCIAS.png" 
                    alt="Ícono de referencias verde"
                    class="mx-auto d-block"
                    width="70%">
                    <div class="text-uppercase text-center fw-bold mb-3">
                        Teléfono de referencias personales
                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-3">
                    <img 
                        src="./assets/media/icons/PRESOLICITUD.png" 
                        alt="Ícono de pre solicitud verde"
                        class="mx-auto d-block" 
                        width="70%">
                    <div class="text-uppercase text-center fw-bold mb-3">
                        Llenar pre solicitud
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>