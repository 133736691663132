<div class="container-fluid mt-5">
    <div class="row">

        <div class="col-12 mb-5">
            <div class="container">
                <div class="row">

                    <div 
                        class="col-12 text-center text-third"
                        style="font-size: calc(2em + 2vh)">
                        <b>¡Préstamos para fortalecer tu negocio!</b>
                    </div>

                    <div 
                        class="col-12 text-center"
                        style="font-size: calc(1em + 1vh);">
                        <p>
                            Cumple tus metas con <b>SFP Porvenir.</b> Te prestamos para:
                            <b>Equipar, remodelar o para surtir tu negocio.</b>
                        </p>
                    </div>

                </div>
            </div>
        </div>

        <div 
            class="col-12 col-md-4 mb-md-4 ps-md-0">
            <div 
                class="col-12 bg-center" 
                style="background-image: url('./assets/media/img/01.PRESTAMOS PARA TU NEGOCIO SOFIPO PORBENIR.jpg'); height: 33vh;">
            </div>
        </div>

        <div 
            class="col-12 col-md-4 mb-md-4 p-md-0">
            <div 
                class="col-12 bg-center"
                style="background-image: url('./assets/media/img/02.PRESTAMOS PARA TU NEGOCIO SOFIPO PORBENIR.jpg'); height: 33vh;">
            </div>
        </div>

        <div 
            class="col-12 col-md-4 mb-md-4 pe-md-0">
            <div
                class="col-12 bg-center"
                style="background-image: url('./assets/media/img/03.PRESTAMOS PARA TU NEGOCIO SOFIPO PORBENIR.jpg.jpg'); height: 33vh;">
            </div>
        </div>

        <div class="col-12">
            <div class="container">
                <div class="col-12 text-justify mb-3 text-muted small pt-3 pt-md-0">
                    <b>CAT promedio de 144.07% sin IVA.</b> 
                    (CAT: Costo Anual Total) para fines informativos y de comparación. 
                    Fecha de cálculo: enero 2024. Vigencia: diciembre 2024. 
                    Sujeto a aprobación de crédito, cumplimiento de requisitos, verificación de datos, 
                    análisis crediticio y comportamiento de pago. 
                    Incumplir tus obligaciones te puede generar comisiones e intereses moratorios. 
                    Contratar créditos por arriba de tu capacidad de pago puede afectar tu 
                    historial crediticio. Comisión: 0 %
                </div>
            </div>
        </div>
    </div>
</div>