<div 
    class="container-fluid bg-center px-5 py-5"
    style="background-image: url('./assets/media/img/FONDO-CONTACTO.jpg');">

    <div class="row">

        <div class="col-12">
            <div class="row">

                <div class="col-12 col-md-4 mb-4 mb-md-0">
                    <div class="col-12">
                        <div class="row">

                            <div class="col-12 mb-4">
                                <div class="col-6 col-lg-3">
                                    <img 
                                        src="./assets/media/logos/LOGO VERDE CLARO.png" 
                                        alt="Logo Porvenir SFP verde"
                                        width="100%">
                                </div>
                            </div>

                            <div class="col-12 mb-3">
                                Av. Adolfo Ruíz Cortines, Mz 1, Lt20, int 22,
                                Col. Lomas de Atizapán 2ª Sección,
                                C.P 52977
                            </div>

                            <div class="col-12">
                                <b>55 5266-5387</b>
                            </div>

                            <div class="col-12">
                                Horario de atención:
                            </div>

                            <div class="col-12">
                                Lunes a Viernes 9:00AM a 6:00PM
                            </div>

                            <div class="col-12">
                                Sábado 09:00 AM a 3:00PM
                            </div>

                            <div class="col-12 mb-3">
                                imelda.perea@porvenirsofipo.com.mx
                            </div>

                            <div class="col-12 d-none d-md-block mb-3">
                                <div class="row">
                                    <div class="col-5 col-md-3 col-lg-2 col-xl-3 col-xxl-1">
                                        <img 
                                            src="./assets/media/icons/ICONO-MAIL.png"
                                            alt="Ícono de correo verde"
                                            width="100%">
                                    </div>
                                    <div class="col-5 col-md-3 col-lg-2 col-xl-3 col-xxl-1">
                                        <img 
                                            src="./assets/media/icons/ICONO-WA.png"
                                            alt="Ícono de WhatsApp verde"
                                            width="100%">
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-4 mb-4 mb-md-0">
                    <div class="col-12">
                        <div class="row">

                            <div 
                                class="col-12 text-third mb-3"
                                style="font-size: calc(0.8em + 0.8vh);">
                                <b>Regulación</b>
                            </div>

                            <div 
                                class="col-12 mb-2"
                                *ngFor="let child of regulationMenu">
                                <a
                                    [href]="child.path"
                                    class="text-decoration-none text-decoration-hover text-black">
                                    {{ child.name }}
                                </a>
                            </div>

                        </div>
                    </div>
                </div>


                <div class="col-12 col-md-4 mb-4 mb-md-0">
                    <div class="col-12">
                        <div class="row">

                            <div 
                                class="col-12 text-third mb-4"
                                style="font-size: calc(0.8em + 0.8vh);">
                                <b>CONTÁCTANOS</b>
                            </div>

                            <div class="col-12">
                                <form>
                                    <div class="mb-3">
                                      <input 
                                        type="text" 
                                        class="form-control border-0 rounded-0"
                                        placeholder="Nombre">
                                    </div>
                                    <div class="mb-3">
                                        <input 
                                        type="text" 
                                        class="form-control border-0 rounded-0"
                                        placeholder="Correo electrónico">
                                    </div>
                                    <div class="mb-3">
                                        <textarea 
                                            class="form-control border-0 rounded-0" 
                                            rows="3"
                                            placeholder="Mensaje">
                                        </textarea>                                  
                                    </div>

                                    <div>
                                        <button
                                            class="btn btn-light rounded-0 px-4">
                                            <b>Enviar</b>
                                        </button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="col-12">
            <div class="row">

                <div class="col-8 col-md-6">
                    
                    <div class="row">

                        <div class="col-12">
                            <small><a class="text-decoration-none text-decoration-hover text-black" href="/terms/privacy-notice">AVISO DE PRIVACIDAD</a> | <a class="text-decoration-none text-decoration-hover text-black">REGULACIÓN</a></small>
                        </div>

                        <div class="col-12">
                            <small>SFP PORVENIR 2023 © Todos los derechos reservados.</small>
                        </div>

                    </div>
                </div>

                <div class="col-4 col-md-6 text-end">
                    <a
                        href="/regulations/financial-institutions-bureau">
                        <img 
                            src="./assets/media/logos/buro.png"
                            width="80%"
                            class="d-block d-md-none img-fluid ms-auto">
                    </a>

                    <a
                        href="/regulations/financial-institutions-bureau">
                        <img 
                            src="./assets/media/logos/buro.png"
                            style="width: calc(2rem + 2vh);"
                            class="d-none d-md-block ms-auto">
                    </a>
                </div>

                <div class="col-6 pt-3 d-block d-md-none">
                    <div class="row justify-content-md-end">
                        <div class="col-5 col-md-2 col-lg-2 col-xl-1">
                            <img 
                                src="./assets/media/icons/ICONO-MAIL.png"
                                alt="Ícono de correo verde"
                                width="100%">
                        </div>
                        <div class="col-5 col-md-2 col-lg-2 col-xl-1">
                            <img 
                                src="./assets/media/icons/ICONO-WA.png"
                                alt="Ícono de WhatsApp verde"
                                width="100%">
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>

</div>