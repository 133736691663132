<div class="container-fluid">
    <div class="row">
        <div 
            class="col-12 col-lg-4 col-xl-2 bg-center"
            style="background-image: url('./assets/media/img/AVISO-IMPORTANTE.png')">
            <div 
                class="d-block d-lg-none"
                style="height: 35vh;">
            </div>
        </div>
        <div class="col-12 col-lg-8 col-xl-10 text-fifth py-5 py-lg-4 py-xl-5 px-4">

            <p 
                class="fw-lighter text-justify"
                style="font-size: calc(0.8em + 0.8vh)">
                <b>SFP Porvenir S.A DE CV SFP,</b>
                le informamos que NO ofrecemos prestamos
                por medio de redes sociales, llamadas telefónicas, medios impresos, personales,
                etc.
                <b>Se han detectado FRAUDES solicitando dar
                dinero por anticipado,
                cobros por trámites o aperturas, te sugerimos No dar dinero bajo ningún
                motivo para la obtención del crédito o cualquier otro servicio.</b>
                NO CONTAMOS
                CON NINGUNA OTRA SUCURSAL DENTRO O FUERA DE LA REPUBLCA MEXICANA
                EXCEPTO A LA UBICADA EN Av. Adolfo Ruíz Cortines, Mz 1, Lt20, int 22, Col. Lomas
                de Atizapán 2ª Sección, C.P 52977.

            </p>
            <p c></p>
        </div>

    </div>
</div>