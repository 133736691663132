import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  
  public apiLoaded: Observable<boolean>;
  //Agreamos la latitud
  public center: google.maps.LatLngLiteral = {lat: 19.552425064789325, lng: -99.2746352687918};

  //Zoom del mapa
  public zoom = 16;


  //Tags del mark
  public markers = [{

    position:{
    lat: 19.552425064789325,
    lng: -99.2746352687918,
    },

    visible: false,
    opacity: 0.1,
    label: {
      text: 'Nuestra sucursal',
    },
    title: ''
  }];

  constructor(httpClient: HttpClient) {
    this.apiLoaded = httpClient.jsonp(`https://maps.googleapis.com/maps/api/js?key=${environment.apiKeys.googleMaps}`, 'callback')
        .pipe(
          map(() => true),
          catchError(() => of(false)),
        );

  }

  ngOnInit(): void {
    
  }

}
