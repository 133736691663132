import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent {

  constructor(
    private metaService: Meta,
    private titleService: Title
  ) {
    this.titleService.setTitle('Inicio • Porvenir SFP');
    this.metaService.updateTag({ 
      name: 'description', 
      content: 'Porvenir SOFIPO es una institución regulada por la comisión Nacional Bancaria y de Valores CNBV. Requisitos para solicitar un mayor préstamo: Buen historial crediticio, INE, comprobante de domicilio. En caso de tener negocio propio; RFC, alta en SHCP, comprobante de domicilio. Documentos internos; Solicitud de crédito, aviso de privacidad, autorización de consulta de buró de crédito firmado Para obtener un mejor péstamo es indispensable contar con un historial crediticio bueno.'
    });
  }

}
