import { Component } from '@angular/core';
import { REGULATION_DATA } from 'src/app/data/docs.data';
import { Doc } from 'src/app/models/doc.model';

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss']
})
export class MainFooterComponent {

  public regulationMenu: Doc[] = REGULATION_DATA;

}
