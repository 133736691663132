<div class="container py-5">
  <div class="row">
    <div class="col-12 text-justify">
      <h2 class="text-third mb-4">
        <b>CONDUSEF</b>
      </h2>
      <p>
        Hacemos del conocimiento del cliente los datos del Centro de Atención Telefónica de la Comisión Nacional para la Protección y 
        Defensa de los Usuarios de Servicios Financieros (CONDUSEF), en la Cd. de México.
      </p>
      <p>
        <b>Para atención a usuarios favor de llamar a los teléfonos:</b>
        <br>
        Ciudad de México: 5340 0999
        <br>
        Interior de la república: 800 999 8080
        <br>
        Atención a usuarios en Ave. Insurgentes Sur No. 762, Col. Del Valle, Delegación Benito Juárez, Ciudad de México, C.P 03100.
      </p>
      <p>
        Para más información consulta la pagina de Internet <a href="www.condusef.gob.mx">www.condusef.gob.mx</a>
      </p>
    </div>

    <div class="col-12">
      <img src="./assets/media/logos/condusef.png" style="width: calc(8rem + 8vh);">
    </div>

  </div>
</div>