import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy-notice-page',
  templateUrl: './privacy-notice-page.component.html',
  styleUrls: ['./privacy-notice-page.component.scss']
})
export class PrivacyNoticePageComponent {

  constructor(
    private metaService: Meta,
    private titleService: Title
  ) {
    this.titleService.setTitle('Aviso Privacidad • Porvenir SFP');
    this.metaService.updateTag({ 
      name: 'description', 
      content: 'Aviso de privacidad'
    });
  }

}
