import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'sofipo-web';

  constructor(
    private metaService: Meta,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {

    this.router.events.subscribe(event => {

      let canonicalURL = `${environment.canonicalDomain}${this.document.location.pathname}`;

      // console.log(canonicalURL);

      if (event instanceof NavigationEnd) {
        this.setCanonicalURL(canonicalURL);
      }
      
    });

  }

  /*
  ██████╗ ███████╗███╗   ███╗ ██████╗ ██╗   ██╗███████╗ ██████╗ █████╗ ███╗   ██╗ ██████╗ ███╗   ██╗██╗ ██████╗ █████╗ ██╗  ████████╗ █████╗  ██████╗
  ██╔══██╗██╔════╝████╗ ████║██╔═══██╗██║   ██║██╔════╝██╔════╝██╔══██╗████╗  ██║██╔═══██╗████╗  ██║██║██╔════╝██╔══██╗██║  ╚══██╔══╝██╔══██╗██╔════╝
  ██████╔╝█████╗  ██╔████╔██║██║   ██║██║   ██║█████╗  ██║     ███████║██╔██╗ ██║██║   ██║██╔██╗ ██║██║██║     ███████║██║     ██║   ███████║██║  ███╗
  ██╔══██╗██╔══╝  ██║╚██╔╝██║██║   ██║╚██╗ ██╔╝██╔══╝  ██║     ██╔══██║██║╚██╗██║██║   ██║██║╚██╗██║██║██║     ██╔══██║██║     ██║   ██╔══██║██║   ██║
  ██║  ██║███████╗██║ ╚═╝ ██║╚██████╔╝ ╚████╔╝ ███████╗╚██████╗██║  ██║██║ ╚████║╚██████╔╝██║ ╚████║██║╚██████╗██║  ██║███████╗██║   ██║  ██║╚██████╔╝
  ╚═╝  ╚═╝╚══════╝╚═╝     ╚═╝ ╚═════╝   ╚═══╝  ╚══════╝ ╚═════╝╚═╝  ╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝  ╚═══╝╚═╝ ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝   ╚═╝  ╚═╝ ╚═════╝

  */
  /**
   * FUNCTION removeCanonicalTag
   */
  removeCanonicalTag() {
    this.metaService.removeTag('rel="canonical"');
  }

  /*
  ███████╗███████╗████████╗ ██████╗ █████╗ ███╗   ██╗ ██████╗ ███╗   ██╗██╗ ██████╗ █████╗ ██╗     ██╗   ██╗██████╗ ██╗
  ██╔════╝██╔════╝╚══██╔══╝██╔════╝██╔══██╗████╗  ██║██╔═══██╗████╗  ██║██║██╔════╝██╔══██╗██║     ██║   ██║██╔══██╗██║
  ███████╗█████╗     ██║   ██║     ███████║██╔██╗ ██║██║   ██║██╔██╗ ██║██║██║     ███████║██║     ██║   ██║██████╔╝██║
  ╚════██║██╔══╝     ██║   ██║     ██╔══██║██║╚██╗██║██║   ██║██║╚██╗██║██║██║     ██╔══██║██║     ██║   ██║██╔══██╗██║
  ███████║███████╗   ██║   ╚██████╗██║  ██║██║ ╚████║╚██████╔╝██║ ╚████║██║╚██████╗██║  ██║███████╗╚██████╔╝██║  ██║███████╗
  ╚══════╝╚══════╝   ╚═╝    ╚═════╝╚═╝  ╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝  ╚═══╝╚═╝ ╚═════╝╚═╝  ╚═╝╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

  */
  /**
   * FUNCTION setCanonicalURL
   * 
   * @param url 
   */
  setCanonicalURL(url: string) {
    // Elimina la etiqueta canonical anterior si existe
    this.removeCanonicalTag();

    // Agrega la nueva etiqueta canonical
    this.metaService.addTag({ rel: 'canonical', href: url });
  }
}
