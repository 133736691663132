import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { BranchesPageComponent } from './pages/branches-page/branches-page.component';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { MainFooterComponent } from './components/main-footer/main-footer.component';
import { MainTopBannerComponent } from './components/main-top-banner/main-top-banner.component';
import { NoticeComponent } from './components/notice/notice.component';
import { CommercialMessageBannerComponent } from './components/commercial-message-banner/commercial-message-banner.component';
import { LoansComponent } from './components/loans/loans.component';
import { RequirementsComponent } from './components/requirements/requirements.component';
import { OurBranchTopBannerComponent } from './components/our-branch-top-banner/our-branch-top-banner.component';
import { MapComponent } from './components/map/map.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PrivacyNoticePageComponent } from './pages/privacy-notice-page/privacy-notice-page.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CondusefPageComponent } from './pages/regulations/condusef-page/condusef-page.component';
import { FinancialInstitutionsBureauPageComponent } from './pages/regulations/financial-institutions-bureau-page/financial-institutions-bureau-page.component';

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    HomePageComponent,
    BranchesPageComponent,
    MainHeaderComponent,
    MainFooterComponent,
    MainTopBannerComponent,
    NoticeComponent,
    CommercialMessageBannerComponent,
    LoansComponent,
    RequirementsComponent,
    OurBranchTopBannerComponent,
    MapComponent,
    PrivacyNoticePageComponent,
    SideMenuComponent,
    CondusefPageComponent,
    FinancialInstitutionsBureauPageComponent
    
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    NgbModule,
    CommonModule,
    GoogleMapsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgbDropdownModule
  ],
  providers: [
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
