import { Component } from '@angular/core';

@Component({
  selector: 'app-commercial-message-banner',
  templateUrl: './commercial-message-banner.component.html',
  styleUrls: ['./commercial-message-banner.component.scss']
})
export class CommercialMessageBannerComponent {

}
