<div class="container-fluid">
   <div class="row">

      <div 
         class="col-12 text-center py-4 bg-center text-white"
         style="font-size: calc(1.5em + 1.5vh); background-image: url('./assets/media/banners/TOP-BANNER.jpg');">

         <b>Aviso de Privacidad SFP PORVENIR, S.A. DE C.V., S.F.P.</b>

      </div>

      <div class="col-12 py-5">
         <div class="container">
            <div class="row">

               <div class="col-12 text-justify">
                  <b>SFP PORVENIR, S.A. DE C.V., S.F.P.</b> (en adelante, “<b>SFP PORVENIR</b>”), es el responsable del uso
                  y protección de sus datos personales. “<b>SFP PORVENIR</b>” tiene la convicción de proteger la
                  información personal (en adelante, los “<b>Datos Personales</b>”) proporcionada por usted, así como
                  por sus clientes y posibles clientes, proveedores, acreedores, contratistas y otros terceros
                  relacionados con “SFP PORVENIR” y es responsable de su Tratamiento (según se define más
                  adelante) cuando sean recabados a través de las operaciones de negocios que “<b>SFP PORVENIR</b>”
                  realice con el Titular, y durante la vigencia de la relación jurídica correspondiente.
                  <br><br>
                  De conformidad con lo previsto en la Ley Federal de Protección de Datos Personales en
                  Posesión de los Particulares, su Reglamento y los Lineamientos del Aviso de Privacidad, por
                  medio del presente, le damos a conocer a “Usted” (indistintamente “Usted” o el “Titular”), el
                  presente Aviso de Privacidad (en lo sucesivo el “Aviso de Privacidad”), mismo que le aplicará
                  a “Usted” como cliente, usuario, o proveedor de “<b>SFP PORVENIR</b>” (“Nosotros”).
                  <br><br>
                  Deseamos que “Usted” comprenda nuestras prácticas de privacidad, específicamente, qué
                  datos personales e información recopilamos, como se usa esa información, con quien
                  compartimos esa información y qué hacemos para protegerla; asimismo nos comprometemos
                  en términos de Ley a velar por el cumplimiento de los principios de protección de sus datos
                  personales adoptando las medidas necesarias para su aplicación siguiendo las prácticas de
                  privacidad de conformidad con los ordenamientos legales aplicables en esta materia, conforme
                  a lo siguiente:
                  <br><br>
                  <b>a) DE NUESTRA IDENTIDAD Y DOMICILIO:</b> ostentamos nuestro domicilio en Av. Adolfo Ruiz
                  Cortines, MZ. 1, LT. 20, Oficina. 22, 2ª Sección de Lomas de Atizapán, Atizapán de Zaragoza,
                  C.P. 52977; seremos los responsables de tratar los datos personales que nos proporcione de
                  conformidad con lo dispuesto en el presente Aviso de Privacidad, señalando el domicilio
                  referido para todos los efectos legales derivados del manejo de la información proporcionada
                  en los formularios de registro de datos personales contenidos en el sitio web: www.porvenirsofipo.mx
                  y los demás sitios web de nuestra propiedad, o por vía electrónica, óptica, sonora, visual o por
                  cualquier otro medio o tecnología (el “Sitio”).
                  <br><br>
                  <b>b) DE LOS DATOS PERSONALES QUE RECABAREMOS:</b> Los datos que “Usted” nos
                  proporcionará en el “Sitio”, a través de los formatos de registro que son puestos a su disposición
                  serán proporcionados de forma libre y voluntaria para ser tratados conforme a la Sección d) y
                  e) del presente Aviso de Privacidad, serán de manera enunciativa mas no limitativa:(i) fotografía
                  (ii) Nombre(s), apellido paterno y apellido materno; (iii) domicilio; (iv) fecha de nacimiento;
                  (v) número telefónico fijo y/o celular; (vi) correo electrónico; (vii) estado civil; (viii) grado de
                  estudios y ocupación; (ix) datos patrimoniales o financieros; (x) historial laboral; (xi) RFC;
                  (xii) CURP; (xiii) Número de Seguridad Social; (xiv) género; (xv) Grado de Estudios;
                  (xvi) créditos con el Instituto del Fondo Nacional para la Vivienda de los trabajadores
                  (INFONAVIT); xvii)comportamiento crediticio en sociedades de información crediticia;
                  (xviii) referencias laborales y personales; (xix) datos económicos y financieros;(en lo sucesivo
                  los “Datos”). “Nosotros” podremos realizar las investigaciones y acciones quede consideren
                  necesarias, a efecto de comprobar directamente o a través de terceros contratados para ello,
                  dependencia u autoridad, la veracidad de nos sean proporcionados.
                  <br><br>
                  <b>c) DE LOS DATOS PERSONALES SENSIBLES QUE RECABAREMOS:</b> La información solicitada
                  es considerada conforme a la Ley como “datos personales sensibles”, es decir, aquellos datos
                  personales íntimos o cuya utilización indebida pueda dar origen a discriminación o conlleve
                  un riesgo grave para “Usted”. En particular, los datos personales que le recabaremos serán
                  utilizados para la integración de su perfil económico y financiero, incluyendo el análisis de su
                  estatus de cumplimiento de obligaciones económicas con terceros, a fin de formalizar la
                  contratación de los productos relacionados con el desarrollo de nuestras actividades
                  económicas, que darán origen a nuestra relación contractual con usted como “Titular”, por lo
                  que su integración a la base de datos correspondientes se encontrará en términos de Ley
                  debidamente justificados.
                  <br><br>
                  <b>d) DEL TRATAMIENTO QUE LE DAREMOS A SUS DATOS:</b> El tratamiento que le daremos a los
                  Datos que “Usted” nos proporcione, será el necesario, adecuado y relevante para los fines de
                  nuestra relación jurídica y contractual, conforme a los siguientes lineamientos: Como finalidades
                  principales sus datos personales serán utilizados: (i) Para corroborar su identidad y veracidad de
                  la información proporcionada, incluyendo la de sus beneficiarios, referencias, obligados solidarios,
                  avales o fiadores, según resulte aplicable; (ii) Para integrar expedientes, bases de datos y sistemas
                  necesarios para llevar a cabo las operaciones correspondientes; (iii) Para cumplimentar disposiciones
                  de conocimiento del cliente y prevención de lavado de dinero; (iv) para mantener la relación
                  jurídica que se genere; (v) Para desarrollar, cuando resulte aplicable, la promoción de premios,
                  sistemas de puntuación y programas de lealtad y cumplimiento, así como en su caso, los procesos
                  de cobranza que pudieran derivar de nuestra relación jurídica y contractual; (vi) Para llevar a
                  cabo análisis de riesgo y viabilidad de crédito; y cumplir con regulaciones, políticas y medidas de
                  seguridad física y protección civil de nuestros establecimientos / sucursales, tales como sistemas
                  de video vigilancia y acceso; (vii) Para realizar las investigaciones y acciones que consideremos
                  justificadas, a efecto de comprobar a través de terceros contratados para ello, dependencia u
                  autoridad, la veracidad de los datos que nos sean proporcionados. Independientemente de lo
                  anterior, sus datos personales podrán ser utilizados para las siguientes finalidades secundarias:
                  (i) mercadotecnia, publicidad y prospección comercial y promoción de productos diversos
                  relacionados, o no, con el giro contratado en nuestra relación jurídica base; (ii) para realizar análisis
                  estadístico, de generación de modelos de información y/o perfiles de comportamiento actual
                  y predictivo, y participar en encuestas, sorteos y promociones; y (iii) para gestión de seguros;
                  (iv) así como para todos los fines relacionados con el cumplimiento de compromisos y obligaciones
                  derivados de nuestra relación jurídica y contractual. No obstante, lo anterior, Usted puede manifestar
                  su negativa para que sus datos no sean tratados para los fines descritos en este párrafo a través
                  del procedimiento detallado en el presente Aviso de Privacidad, sin embargo, de tomar en cuenta
                  que al proporcionarnos sus datos y aceptar los términos y condiciones del mismo, “Usted” acepta
                  de forma expresa que tratemos dichos datos para los fines señalados con anterioridad.
                  <br><br>
                  <b>e) DE LA TRANSFERENCIA QUE HAREMOS DE SUS DATOS:</b> Para poder cumplir con la finalidad
                  del presente Aviso de Privacidad y con la aceptación que “Usted” otorga a sus términos y condiciones,
                  Nosotros quedamos habilitados para transferir sus Datos a terceras personas, físicas o morales,
                  nacionales o extranjeras, empresas filiales o subsidiarias, pertenecientes a cualquier ramo industrial
                  y/o de servicios. No obstante lo anterior, sus datos serán utilizados para los mismos fines descritos
                  en el inciso inmediato anterior y conservados en diferentes medios seguros que la tecnología
                  permita, cuyo acceso estará limitado solamente a las personas físicas y/o morales con las que,
                  en su caso, compartamos su información. Contamos con las medidas de seguridad adecuadas
                  para proteger el uso de sus Datos por parte de terceros no autorizados. En caso de requerimiento
                  de alguna autoridad, sus Datos podrán ponerse a disposición de esta, dentro del estricto
                  cumplimiento de los ordenamientos legales aplicables.
                  <br><br>
                  <b>f) CONSENTIMIENTO:</b> De conformidad con la Ley Federal de Protección de Datos Personales
                  en Posesión de los Particulares (la “Ley”), no es necesario otorgar el consentimiento del Titular
                  para el Tratamiento de Datos Personales cuando éstos sean proporcionados a “SFP PORVENIR”,
                  a fin de dar cumplimiento con las obligaciones derivadas de la relación jurídica entre el Titular
                  y “SFP PORVENIR”. No obstante, lo anterior, “SFP PORVENIR” y los Titulares podrán pactar
                  medios y formas para otorgar dicho consentimiento, como por ejemplo, mediante otros avisos
                  de privacidad o conforme lo requiera la legislación aplicable.
                  <br><br>
                  <b>g) COMO LIMITAR EL USO O DIVULGACIÓN DE LOS DATOS PERSONALES:</b> Hemos adoptado
                  los niveles de seguridad y de protección de datos personales requeridos por Ley, sin perjuicio
                  de que pueda implementar otros medios y medidas técnicas para evitar la alteración, acceso no
                  autorizado de manera enunciativa no limitativa, de los datos personales proporcionados por
                  “Usted”. La información proporcionada puede ser revocada en cualquier momento de acuerdo
                  al procedimiento que se menciona en el presente aviso de privacidad, exceptuando aquellas
                  que: deban ser tratadas por disposición legal; se refieran a las partes de un contrato privado,
                  social o administrativo y sean necesarios para su desarrollo y cumplimiento; cuando obstaculice
                  actuaciones judiciales o administrativas vinculadas a obligaciones fiscales, la investigación y
                  persecución de delitos o la actualización de sanciones administrativas; aquellas que sean
                  necesarias para proteger los intereses jurídicamente tutelados del “Titular”; sean necesarias para
                  realizar una acción en función del interés público; sean necesarios para cumplir con una
                  obligación legalmente adquirida por “Usted”.
                  <br><br>
                  <b>h) DE LOS MEDIOS PARA EJERCER LOS DERECHOS ARCO:</b> Usted tendrá en todo momento
                  el acceso a sus Datos para solicitar su rectificación, cancelación u oposición (en lo sucesivo los
                  “Derechos ARCO”). Para el ejercicio de los Derechos ARCO, deberá dirigirnos su respectiva
                  solicitud en forma escrita, a la dirección de correo imelda.perea@porvenirsofipo.com.mx,
                  dirigiendo su mensaje a “SFP PORVENIR” y a la atención de la C. Imelda Perea. La solicitud para
                  ejercer los Derechos ARCO deberá contener y estar acompañada de lo siguiente: (i) el nombre
                  del “Titular”, su domicilio y/o correo electrónico y/o cualquier otro medio para comunicarle la
                  respuesta a su solicitud; (ii) los documentos oficiales que acrediten la identidad o, en su caso, la
                  representación legal del “Titular”; (iii) la descripción clara y precisa que, en su caso, motive su
                  solicitud o reclamación y los preceptos que considere han sido vulnerados, así como los Datos
                  Personales, respecto de los que se busca ejercer alguno de los derechos antes mencionados;
                  y (iv) cualquier otro elemento o documento que facilite la localización de los Datos Personales.
                  Recibido el escrito por el que nos indique que hará uso de sus Derechos ARCO, tendremos
                  TREINTA días hábiles para analizar, atender y enviarle la respuesta correspondiente. El medio
                  por el cual le enviaremos nuestra conclusión, será aquel que nos sea indicado por “Usted” en
                  su solicitud o, en su defecto, por el mismo medio por el cual nos hizo llegar su escrito.
                  <br><br>
                  <b>i) DE LOS MECANISMOS DE COMUNICACIÓN:</b> Cualquier comunicación que desee dirigirnos
                  con motivo del presente Aviso de Privacidad o para limitar el uso y divulgación de sus Datos
                  Personales, diferentes a los procedimientos que hemos establecido en el presente Aviso de
                  Privacidad, deberá realizarse a las direcciones proporcionadas para el ejercicio de los Derechos
                  ARCO, mencionando de manera clara y precisa: (i) el nombre del Titular, su domicilio y correo
                  electrónico; (ii) los documentos oficiales que acrediten la identidad o, en su caso, la representación
                  legal del Titular; y (iii) el objeto de su comunicación.
                  <br><br>
                  <b>j) DE LOS MEDIOS REMOTOS:</b> Si por cualquier razón “Usted” nos proporciona sus Datos a
                  través de medios electrónicos, incluyendo nuestro sitio web (Internet), “Usted” entiende, acepta
                  y reconoce que: Nuestro sitio web puede incluir enlaces a sitios web de terceros que, en caso de
                  acceder, ocasionará que se abandone nuestro sitio web, por lo cual, no asumiríamos ninguna
                  responsabilidad en relación con los sitios web de terceros. Nuestro sitio web puede incluir
                  enlaces a sitios que administran redes sociales, en cuyo caso Usted acepta que, al proporcionar
                  cualquier tipo de información, incluso sus Datos en dichos sitios, ocasionará que la misma pueda
                  ser leída, vista, accedida, retransmitida y tratada por cualquier persona, liberándonos de
                  cualquier responsabilidad. Es posible que nuestros sistemas recopilen datos adicionales a los
                  que Usted nos proporcione, como lo son el tipo de navegador, el sistema operativo, las páginas
                  de Internet visitadas, la dirección IP, etc., a través “cookies” o “web beacons”, entre otros. Para
                  poder deshabilitar estos sistemas, deberá acceder a “Opciones de internet” en la barra de
                  “Herramientas” del navegador que utilice. De la misma manera, Nosotros atenderemos y
                  responderemos cualquier comunicación que nos sea dirigida dentro de los TREINTA días
                  hábiles al haber recibido dicho escrito. El medio por el cual le daremos respuesta, será en la
                  forma que haya indicado Usted, o en su defecto, utilizaremos el mismo medio por el cual nos
                  hizo llegar su comunicación.
                  <br><br>
                  <b>k) DE LAS MODIFICACIONES AL AVISO DE PRIVACIDAD:</b> El presente Aviso de Privacidad podrá
                  ser modificado por “Nosotros” tantas veces como lo consideremos necesario, tanto para guardar
                  congruencia con los ordenamientos legales aplicables, a petición de las autoridades competentes
                  y/o a nuestra propia conveniencia. En tanto sigamos utilizando su información o en el caso que
                  modifiquemos nuestra identidad, requiramos de “Usted” más datos de los aquí mencionados,
                  modifiquemos el fin del presente Aviso de Privacidad y/o cambien las condiciones de transferencia,
                  cualquier cambio que realicemos se lo pondremos a su disposición a través de nuestro sitio
                  www.porvenirsofipo.mx por lo que recomendamos visitarlo periódicamente. Consiento que toda
                  mi información mencionada en la Sección b) y c), sea tratada únicamente para la finalidad señalada
                  por la Sección d) y e) del presente Aviso de Privacidad.
               </div>

            </div>
         </div>
      </div>

   </div>
</div>