<div class="container-fluid py-5 px-2 px-lg-5">
  <div class="row">
    <div class="col-12 text-justify mb-5" style="overflow-x: hidden;">
      <h2 class="text-third mb-4">
        <b>Buró de Entidades Financieras</b>
      </h2>
      <p class="text-third ">
        <b>¿Qué es el Buró de Entidades Financieras?</b>
      </p>
      <p>
        Es una herramienta de consulta y difusión con la que podrás conocer los productos que ofrecen las entidades financieras, 
        sus comisiones y tasas, las reclamaciones de los usuarios, las prácticas no sanas en que incurren, las sanciones administrativas que les han impuesto, l
        as cláusulas abusivas de sus contratos y otra información que resulte relevante para informarte sobre su desempeño.
      </p>
      <p>
        Con el Buró de Entidades Financieras, se logrará saber quién es quién en bancos, seguros, sociedades financieras de objeto múltiple, cajas de ahorro, 
        afores, entre otras entidades. Con ello, podrás comparar y evaluar a las entidades financieras, sus productos y servicios y tendrás mayores elementos 
        para elegir lo que más te convenga. Esta información te será útil para elegir un producto financiero y también para conocer y usar mejor los que ya tienes.
      </p>

      <p>
        Este Buró de Entidades Financieras, es una herramienta que puede contribuir al crecimiento económico del país, al promover la competencia entre 
        las instituciones financieras; que impulsará la transparencia al revelar información a los usuarios sobre el desempeño de éstas y los productos 
        que ofrecen y que va a facilitar un manejo responsable de los productos y servicios financieros al conocer a detalle sus características. Lo anterior, 
        podrá derivar en un mayor bienestar social, porque al conjuntar en un solo espacio tan diversa información del sistema financiero, el usuario tendrá más 
        elementos para optimizar su presupuesto, para mejorar sus finanzas personales, para utilizar correctamente los créditos que fortalecerán su economía y 
        obtener los seguros que la protejan, entre otros aspectos.
      </p>
      
      <p>
        En el Buró de Entidades Financieras encontrarás la información correspondiente a SFP PORVENIR S.A. de C.V. Sociedad Financiera Popular y 
        nuestro desempeño frente a los Usuarios, por la prestación de productos y servicios, el cual corresponde únicamente a ésta institución 
        (descarga aquí el comportamiento general en el periodo). Te invitamos a visitar la página web para consulta de información de nuestra Institución, 
        tecleando el nombre de nuestra Institución en el campo de búsqueda (parte superior derecha) en la siguiente 
        liga: <a class="text-third" href="https://www.buro.gob.mx/general_gob.php?id_sector=27&id_periodo=40"><b>https://www.buro.gob.mx/general_gob.php?id_sector=27&id_periodo=40</b></a>
      </p>

      <p>
        Así mismo pone a tu disposición el Catálogo Nacional de Productos y Servicios Financieros en el cual encontrarás el conjunto de 
        fichas técnicas proporcionadas a la Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros como 
        parte de las obligaciones que deben cumplir las instituciones al Buró de Entidades Financieras derivado de la Reforma Financiera. 
        El Catálogo Nacional de Productos y Servicios Financieros es un ejercicio de transparencia de la información en materia de productos y servicios 
        financieros ofrecidos a los Usuarios. Te invitamos a consultar nuestras fichas técnicas en la 
        siguiente dirección: <a class="text-third text-break" href="http://ifit.condusef.gob.mx/ifit/ftb_vista_general.php"><b>http://ifit.condusef.gob.mx/ifit/ftb_vista_general.php</b></a>
      </p>

      <p>
        Si deseas conocer la información de todo el sector de Sociedades Financieras Populares podrás consultarlo 
        en: <a href="http://www.buro.gob.mx/" class="text-third"><b>http://www.buro.gob.mx/</b></a>
      </p>

    </div>

    <div class="col-12 mb-5">
      <img
        src="./assets/media/logos/buro.png"
        style="width: calc(4rem + 4vh);">
    </div>

    <div class="col-12">
      <div class="container-fluid">
        <div class="row">

          <div class="col-md-6 col-lg-2 col-xl-2 px-4 mb-4">
            <div class="row mb-3">
  
              <div 
                class="col-12 bg-third text-white text-center rounded-3 d-flex align-items-center justify-content-center"
                style="height: calc(8rem + 8vh);">
                Institución
              </div>
  
            </div>

            <div class="row">
              <div 
                class="col-12 bg-secondary text-center rounded-3 d-flex align-items-center justify-content-center py-5"
                style="font-size: calc(0.4rem + 0.4vh); height: calc(4.6rem + 4.6vh);">
                SFP Porvenir S.A. de C.V., SFP
              </div>
            </div>

          </div>

          <div class="col-md-6 col-lg-6 col-xl-3 px-4 mb-4">
            <div class="row mb-3">
  
              <div 
                class="col-12 bg-third text-white text-center rounded-3 mb-3 d-flex align-items-center justify-content-center"
                style="height: calc(2rem + 2vh);">
                Reclamaciones
              </div>

              <div class="col-4 ps-0">
                <div 
                  class="col-12 bg-third text-white text-center rounded-3 py-4 d-flex align-items-center justify-content-center px-2"
                  style="height: calc(5.5rem + 5.5vh);">
                  <span style="font-size: calc(0.4rem + 0.4vh);">Total de reclamaciones</span>
                </div>
              </div>

              <div class="col-4 px-1">
                <div 
                  class="col-12 bg-third text-white text-center rounded-3 py-4 d-flex align-items-center justify-content-center px-2"
                  style="height: calc(5.5rem + 5.5vh);">
                  <span style="font-size: calc(0.4rem + 0.4vh);">Índice de reclamación por cada 10 mil clientes <br><br> Trimestral</span>
                </div>
              </div>

              <div class="col-4 pe-0">
                <div 
                  class="col-12 bg-third text-white text-center rounded-3 py-4 d-flex align-items-center justify-content-center px-2"
                  style="height: calc(5.5rem + 5.5vh);">
                  <span style="font-size: calc(0.4rem + 0.4vh);">Índice de desempeño de atención a usuarios (Trimestral) <br><br> Calificación<br>0-10</span>
                </div>
              </div>
  
            </div>

            <div class="row">

              <div class="col-4 ps-0">
                <div class="col-12 bg-secondary text-center rounded-3 py-5 h-100 d-flex align-items-center justify-content-center">
                  8
                </div>
              </div>

              <div class="col-4 px-1">
                <div class="col-12 bg-green-lighten-4 text-center rounded-3 py-5 h-100 d-flex align-items-center justify-content-center">
                  -
                </div>
              </div>

              <div class="col-4 pe-0">
                <div class="col-12 bg-secondary text-center rounded-3 py-5 h-100 d-flex align-items-center justify-content-center">
                  9.69
                </div>
              </div>
              
            </div>

          </div>

          <div class="col-md-6 col-lg-4 col-xl-2 px-4 mb-4">
            <div class="row">
  
              <div 
                class="col-12 bg-third text-white text-center rounded-3 mb-3 d-flex align-items-center justify-content-center"
                style="height: calc(2rem + 2vh);">
                Sanciones
              </div>

              <div class="col-6 ps-0 mb-3">
                <div 
                  class="col-12 bg-third text-white text-center rounded-3 py-5 d-flex align-items-center justify-content-center px-3"
                  style="height: calc(5.5rem + 5.5vh);">
                  <span style="font-size: calc(0.4rem + 0.4vh);">Total de sanciones</span>
                </div>
              </div>

              <div class="col-6 pe-0 mb-3">
                <div 
                  class="col-12 bg-third text-white text-center rounded-3 py-5 d-flex align-items-center justify-content-center px-3"
                  style="height: calc(5.5rem + 5.5vh);">
                  <span style="font-size: calc(0.4rem + 0.4vh);">Monto total de sanción <br><br> ($)</span>
                </div>
              </div>
  
            </div>

            <div class="row">

              <div class="col-6 ps-0">
                <div class="col-12 bg-secondary text-center rounded-3 py-4 h-100 d-flex align-items-center justify-content-center px-3">
                  5
                </div>
              </div>

              <div class="col-6 pe-0">
                <div class="col-12 bg-secondary text-center rounded-3 py-5 h-100 d-flex align-items-center justify-content-center px-3">
                  91,476
                </div>
              </div>

            </div>

          </div>

          <div class="col-md-3 col-lg-2 col-xl-1 px-4 mb-4">
            <div class="row mb-3">
  
              <div 
                class="col-12 bg-third text-white text-center rounded-3 d-flex align-items-center justify-content-center"
                style="height: calc(8rem + 8vh);">
                <span style="font-size: calc(0.4rem + 0.4vh);">Cláusulas Abusivas Particulares (En proceso de eliminación)</span>
              </div>
  
            </div>

            <div class="row">
              <div class="col-12 bg-secondary text-center rounded-3 d-flex align-items-center justify-content-center py-5">
                S/l
              </div>
            </div>

          </div>

          <div class="col-md-3 col-lg-2 col-xl-1 px-4 mb-4">
            <div class="row mb-3">
  
              <div 
                class="col-12 bg-third text-white text-center rounded-3 d-flex align-items-center justify-content-center"
                style="height: calc(8rem + 8vh);">
                <span style="font-size: calc(0.4rem + 0.4vh);">Registro de Unidades Especializadas<br><br>(REUNE)</span>
              </div>
  
            </div>

            <div class="row">
              <div class="col-12 bg-secondary text-center rounded-3 d-flex align-items-center justify-content-center py-5">
                ✅
              </div>
            </div>

          </div>

          <div class="col-md-6 col-lg-6 col-xl-2 px-4 mb-4">
            <div class="row">
  
              <div 
                class="col-12 bg-third text-white text-center rounded-3 mb-3 d-flex align-items-center justify-content-center"
                style="height: calc(2rem + 2vh); font-size: calc(0.5rem + 0.5vh);">
                Programa de Educación Financiera
              </div>

              <div class="col-12 p-0 mb-3">
                <div 
                  class="col-12 bg-third text-white text-center rounded-3 py-5 d-flex align-items-center justify-content-center px-3"
                  style="height: calc(5.4rem + 5.4vh);">
                  <span style="font-size: calc(0.4rem + 0.4vh);">Sinergia con Condusef</span>
                </div>
              </div>
  
            </div>

            <div class="row">

              <div class="col-12 p-0">
                <div class="col-12 bg-secondary text-center rounded-3 py-5 h-100 d-flex align-items-center justify-content-center px-3">
                  ❌
                </div>
              </div>

            </div>

          </div>

          <div class="col-md-6 col-lg-2 col-xl-1 px-4 mb-4">
            <div class="row mb-3">
  
              <div 
                class="col-12 bg-third text-white text-center rounded-3 d-flex align-items-center justify-content-center"
                style="height: calc(8rem + 8vh);">
                <span style="font-size: calc(0.4rem + 0.4vh);">Evaluación de Condusef por Producto<br><br>Calificación<br>(0-10)</span>
              </div>
  
            </div>

            <div class="row">
              <div class="col-12 bg-secondary text-center rounded-3 d-flex align-items-center justify-content-center py-5">
                NA
              </div>
            </div>

          </div>
  
        </div>
      </div>
    </div>

  </div>
</div>