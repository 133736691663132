import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-branches-page',
  templateUrl: './branches-page.component.html',
  styleUrls: ['./branches-page.component.scss']
})
export class BranchesPageComponent {

  constructor(
    private metaService: Meta,
    private titleService: Title
  ) {
    this.titleService.setTitle('Tu Sucursal • Porvenir SFP');
    this.metaService.updateTag({ 
      name: 'description', 
      content: 'Sucursales de Porvenir SOFIPO'
    });
  }

}
