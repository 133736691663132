<div 
    class="container-fluid py-5 mb-4 d-none d-md-block" 
    style="background-image: url('./assets/media/banners/TOP-BANNER.png'); 
        height: 97vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;">
        <div class="row">

            <div class="col-12 col-md-6 col-lg-7">
            </div>

            <div class="col-12 col-md-6 col-lg-5 text-white">
                <div class="row">

                    <div class="col-9 col-md-6 col-lg-4 mb-5">
                        <img class="" src="./assets/media/img/LOGOTIPO BCO.png" alt="Logo Porvenir SFP blanco" width="100%">
                    </div>

                    <div 
                        class="col-12 mt-5 mb-5"
                        style="
                            font-size: calc(2.5em + 2.5vh);
                            line-height : 1em;
                        ">
                        <b>Nuestra
                        <br>
                        sucursal</b>
                    </div>

                    <div class="col-12">
                        <button 
                            class="btn bg-primary text-white rounded-3 px-4"
                            style="font-size: calc(1em + 1vh);">
                            SOLICITA TU CRÉDITO
                        </button>
                    </div>

                </div>
            </div>

        </div>
</div>

<div 
    class="container-fluid py-5 mb-4 d-block d-md-none" 
    style="background-image: url('./assets/media/banners/TOP-BANNER.png'); 
        height: 97vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: left;">
        <div class="row">

            <div class="col-12 col-md-6 col-lg-7">
            </div>

            <div class="col-12 col-md-6 col-lg-5 text-white">
                <div class="row">

                    <div class="col-9 col-md-6 col-lg-4 mb-5">
                        <img class="" src="./assets/media/img/LOGOTIPO BCO.png" alt="Logo Porvenir SFP blanco" width="100%">
                    </div>

                    <div 
                        class="col-12 mt-5 mb-5"
                        style="
                            font-size: calc(2.5em + 2.5vh);
                            line-height : 1em;
                        ">
                        <b>Nuestra
                        <br>
                        sucursal</b>
                    </div>

                    <div class="col-12">
                        <button 
                            class="btn bg-primary text-white rounded-3 px-4"
                            style="font-size: calc(1em + 1vh);">
                            SOLICITA TU CRÉDITO
                        </button>
                    </div>

                </div>
            </div>

        </div>
</div>