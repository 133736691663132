<div class="container-fluid bg-fifth py-3">
    <div class="row">
        <div class="col-12 col-md-3 col-lg-3 col-xl-1 border-end border-3 d-flex align-items-center pe-2 mb-4 mb-md-0">
            <img src="./assets/media/logos/LOGO CNBV.png" alt="Logo CNBV blanco" width="90%">
        </div>
        <div 
            class="col-12 col-md-9 text-white px-5"
            style="font-size: calc(0.48em + 0.48vh);">
            <p class="text-justify">
                <b>Porvenir SOFIPO</b> es una institución regulada por la comisión Nacional Bancaria y de Valores CNBV. Requisitos para solicitar un mayor préstamo: Buen historial crediticio, INE, comprobante de domicilio.
                En caso de tener negocio propio; RFC, alta en SHCP, comprobante de domicilio. Documentos internos; Solicitud de crédito, aviso de privacidad, autorización de consulta de buró de crédito firmado
                Para obtener un mejor péstamo es indispensable contar con un historial crediticio bueno.
            </p>
        </div>
    </div>
</div>