<div class="container-fluid px-2 px-md-5 mb-5">
    <div 
        class="col-12 text-fifth" 
        style="font-size: calc(2.5em + 2.5vh)">
        <b>Lomas de Atizapan</b>
    </div>

    <div 
        class="col-12 p-0"
        *ngIf="apiLoaded | async">
        <google-map 
            class="text-center" 
            height="90vh" 
            width="100%" 
            [center]="center" 
            [zoom]="zoom">
            <map-marker 
                [clickable]="true" 
                *ngFor="let marker of markers" 
                [position]="marker.position"
                [label]="marker.label" [title]="marker.title">
            </map-marker>
        </google-map>
    </div>

    <div class="col-12 bg-fifth px-3 mb-5">
        <div 
            class="text-center text-white" 
            style="font-size: calc(1.1em + 1.1vh)">
            Av. Adolfo Ruíz Cortines, Mz 1, Lt20, int 22, Col. Lomas de Atizapán 2ª Sección,<br>
            C.P 52977 Teléfono: 55 5266-5387
        </div>
    </div>  
</div>