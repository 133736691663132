import { Component } from '@angular/core';

@Component({
  selector: 'app-main-top-banner',
  templateUrl: './main-top-banner.component.html',
  styleUrls: ['./main-top-banner.component.scss']
})
export class MainTopBannerComponent {

}
