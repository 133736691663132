import { Component } from '@angular/core';

@Component({
  selector: 'app-our-branch-top-banner',
  templateUrl: './our-branch-top-banner.component.html',
  styleUrls: ['./our-branch-top-banner.component.scss']
})
export class OurBranchTopBannerComponent {

}
